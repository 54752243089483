import ProjectService from '../services/project.service';
import ClientService from '../services/client.service';
import ProvinceService from '../services/prefecture_jp.service';
import ScheduleService from '../services/schedule.service';
import UserService from '../services/user.service';
import CreativeService from '../services/creative.service';
import NFTService from '../services/nftcomode.service.js';
import statusList from '../data/status';
import moment from 'moment';
import { mapState } from 'vuex';
import socketClient from '../services/socket/SOCKET';

import CommonConst from '@/util/const';

const AUTHORITY = ['クリエイター', '管理者'];
const TYPE = ['個人', '法人'];
// const PROPOSAL_SCREEN_ID = 5;
// const CREATIVE_SCREEN_ID = 4;

export default {
	computed: {
		pagedData() {
			return this.tableData.slice(this.from, this.to);
		},
		/***
		 * Searches through table data and returns a paginated array.
		 * Note that this should not be used for table with a lot of data as it might be slow!
		 * Do the search and the pagination on the server and display the data retrieved from server instead.
		 * @returns {computed.pagedData}
		 */
		queriedData() {
			if (!this.searchQuery) {
				return this.pagedData;
			}
			let result = this.tableData.filter((row) => {
				let isIncluded = false;
				for (let key of this.propsToSearch) {
					let rowValue = row[key].toString();
					if (rowValue.includes && rowValue.includes(this.searchQuery)) {
						isIncluded = true;
					}
				}
				return isIncluded;
			});

			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		},
		...mapState(['userInfo']),
	},
	data() {
		return {
			pagination: {
				perPage: 10,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			searchQuery: '',
			searchedData: [],
			fuseSearch: '',
			allProject: [],
		};
	},
	methods: {
		sortChange({ prop, order }) {
			if (prop) {
				this.tableData.sort((a, b) => {
					let aVal = a[prop];
					let bVal = b[prop];
					console.log(aVal, bVal);
					if (order === 'ascending') {
						return aVal > bVal ? 1 : -1;
					}
					return bVal - aVal ? 1 : -1;
				});
			} else {
				this.tableData.sort((a, b) => {
					return a.id - b.id;
				});
			}
		},
		async getAllProject() {
			let [response, error] = await await ProjectService.getAll();

			if (error) {
				console.log(error);
			}
			return this.sortArrayByKey(response, 'id');
		},

		async getAllClient() {
			let [response, error] = await await ClientService.getAllClient();
			if (error) {
				console.log(error);
			}
			let sortReponse = this.sortArrayByKey(response, 'id');
			return this.filterByDeleteFlag(sortReponse);
		},

		async getAllSchedule() {
			let [response, error] = await await ScheduleService.getAll();
			if (error) {
				console.log(error);
			}
			return response;
		},

		async getProjectById(loginUserId) {
			let query = { [loginUserId.userType]: loginUserId.userId };
			let [response, error] = await await ProjectService.search(query);
			if (error) {
				console.log(error);
			}
			return this.sortArrayByKey(response, 'id');
		},

		async getAllUser() {
			let [response, error] = await await UserService.getAll();
			if (error) {
				console.log(error);
			}
			let sortReponse = this.sortArrayByKey(response, 'id');
			return this.filterByDeleteFlag(sortReponse);
		},

		async filterTableData(loginUserId, userAuth) {
			const provinces = await this.filterProvinces();
			const [projects, users] = await this.getProjectsAndUsers(loginUserId, userAuth);
			const clients = await this.getAllClient();
			const schedules = await this.getAllSchedule();
			let tableData = [];
			if (this.tableName === 'project-table') {
				tableData = this.filterProjectTable(projects, clients, schedules, loginUserId);
			} else if (this.tableName === 'processing-project-table') {
				tableData = this.filterProcessingProjectTable(
					projects,
					clients,
					schedules,
					loginUserId
				);
			} else if (this.tableName === 'client-table') {
				tableData = this.filterClientTable(clients, provinces);
			} else if (this.tableName === 'user-table') {
				tableData = this.filterUserTable(users, loginUserId);
			} else if (this.tableName === 'nft-table') {
				tableData = await this.filterNFtByUser(loginUserId);
			}

			this.tableData = tableData;
			this.originalTableData = tableData;
			this.createClientSelectOption(clients);
		},

		async filterdEditProjectData(projectId) {
			let editedProject = await this.getEditProjectById(projectId);

			const clientList = await this.getAllClient();
			this.createClientSelectOption(clientList);
			let selectedClientInfo = [];
			this.clientOptions.map((option) => {
				if (option.value === editedProject.client_id) {
					selectedClientInfo.push(option);
				}
			});

			let nullValueKeys = Object.keys(editedProject).filter(
				(key) => editedProject[key] === null
			);

			nullValueKeys.map((key) => {
				editedProject[key] = undefined;
			});

			const clientEndPoint = await this.checkCreativeCountingDown(editedProject);
			const filterdEditProjectData = {
				clientInfo: selectedClientInfo[0],
				projectRemark: editedProject.comment,
				projectName: editedProject.project_name,
				projectStartDate: editedProject.project_start_date,
				productionCosts: editedProject.cost,
				url: editedProject.process_client_url + clientEndPoint,
				previewUrl: editedProject.process_preview_client_url + clientEndPoint,
				withHoldingTax: editedProject.with_holding_tax == 1 ? [1] : [],
				notifyMethod: editedProject.notify_method,
				clientRating: editedProject.client_rating,
				responsibleUser: editedProject.responsible_user,
				creatorRating: editedProject.creator_rating,
			};
			this.model = { ...filterdEditProjectData };
		},

		async filterEditClientProjectData(clientId) {
			const clientList = await this.getAllClient();
			const provinceList = await this.getProvince();
			let [projectList, _] = await await ProjectService.search({
				client_id: clientId,
			});
			const editedClientInfo = clientList.find(
				(client) => client.id === parseInt(clientId)
			);
			let [res, error] = await ClientService.getLineVerifyUrl(editedClientInfo['id']);
			let lineVerifyUrl = '';
			if (res) {
				lineVerifyUrl = res.line_verify_url;
			}
			const filterdEditClientData = {
				clientName: editedClientInfo.client_name,
				// postcode: editedClientInfo.postal_code,
				postcodeAddressJapan: editedClientInfo.postal_code,
				address: editedClientInfo.address,
				clientRemark: editedClientInfo.remark,
				managerName: editedClientInfo.responsible_name,
				managerNameInFurigana: editedClientInfo.responsible_name_furigana,
				phoneNumber: editedClientInfo.phone_number,
				faxNumber: editedClientInfo.fax_number,
				mailAddress: editedClientInfo.email_address,
				contractDate: editedClientInfo.contract_start_date,
				bankName: editedClientInfo.bank_name,
				bankBranchName: editedClientInfo.branch_name,
				bankAccountType: editedClientInfo.account_type,
				bankAccountHolder: editedClientInfo.account_holder,
				bankAccountNumber: editedClientInfo.account_number,
				webSiteName: editedClientInfo.web_site,
				instagramAccount: editedClientInfo.instagram,
				facebookAccount: editedClientInfo.facebook,
				twitterAccount: editedClientInfo.twitter,
				lineVerifyUrl: lineVerifyUrl,
			};

			this.provinceSelect = {
				value: editedClientInfo.province_id,
				label: provinceList[editedClientInfo.province_id],
			};
			// Check if client have any active project
			let activeProject = projectList.filter(
				(project) =>
					project.status != CommonConst.FINISH_STATUS && project.delete_flag == 0
			);
			if (activeProject.length > 0) {
				this.isClientHaveActiveProject = true;
			} else {
				this.isClientHaveActiveProject = false;
			}
			this.model = filterdEditClientData;
		},

		async filterNFtByUser(userId) {
			const allProject = await this.getAllProject();
			let allNftCreatedByUser = await this.getNftByUserId(userId);
			let newAllNFTCreatedByUser = allNftCreatedByUser.map((nft) => {
				let project = allProject.find((project) => project.id === nft.project_id);
				nft['project_name'] = project.project_name;
				return nft;
			});
			let tableData = [];
			newAllNFTCreatedByUser.map((nft) => {
				tableData.push({
					id: nft['id'],
					projectName: nft['project_name'],
					fileName: nft['file_name'],
					registerDate: moment(nft['date_created']).format('YYYY/MM/DD'),
					url: `https://opensea.io/ja/assets/matic/${nft['contract_address']}/${nft['token_id']}`,
				});
			});
			return tableData;
		},

		async getNftByUserId(userId) {
			try {
				let response = await NFTService.getByUser(userId);
				return this.sortArrayByKey(response[0], 'id');
			} catch (error) {
				console.log(error);
			}
		},

		async filterProvinces() {
			let provinces = await this.getProvince();
			let provinceOptions = [];
			provinces.map((province, idx) => {
				let provinceInfo = {
					value: idx,
					label: province,
				};
				provinceOptions.push(provinceInfo);
			});
			this.provinceOptions = [{ value: '', label: '選択なし' }, ...provinceOptions];
			return provinces;
		},

		/**
		 *
		 * @param {Number} loginUserId
		 * @param {Number} userAuth user authority
		 * @returns
		 */
		async getProjectsAndUsers(loginUserId, userAuth) {
			let projects = [];
			let users = [];
			if (userAuth == 2) {
				//get all project
				projects = await this.getAllProject();
				users = await this.getAllUser();
			} else if (userAuth == 1) {
				//get all project of specific creatorId
				let info = {
					userId: loginUserId,
					userType: 'responsible_user',
				};
				projects = await this.getProjectById(info);
			} else if (userAuth == 3) {
				//get all project of specific client_id
				let info = {
					userId: loginUserId,
					userType: 'client_id',
				};
				projects = await this.getProjectById(info);
			} else {
				throw new Error('userAuth is not valid');
			}
			projects = this.filterByDeleteFlag(projects);
			return [projects, users];
		},

		/**
		 *
		 * @param {Array} projects Array of projects
		 * @param {Array} clients Array of clients
		 * @param {Array} loginUserId
		 * @returns Array after filter
		 */
		filterProjectTable(projects, clients, schedules, loginUserId) {
			let tableData = [];
			projects.forEach((project) => {
				let clientId = project.client_id;
				clients.forEach((client) => {
					if (client.id === clientId) {
						let urlEndPoint =
							this.userInfo?.authority != 3
								? this.getCreatorUrlEndPoint(project)
								: this.getClientUrlEndPoint(project);
						let schedule =
							schedules.filter((schedule) => schedule.project_id === project.id)[0] ||
							{};
						let projectInfo = {
							id: project.id,
							projectname: project.project_name,
							clientname: client.client_name,
							status: Boolean(project.creator_start)
								? statusList[project.status].text
								: 'スタート',
							startdate: project.project_start_date
								? moment(project.project_start_date).format('YYYY/MM/DD')
								: '',
							delivery: schedule.delivery_date
								? moment(schedule.delivery_date).format('YYYY/MM/DD')
								: '',
							url:
								(this.userInfo?.authority != 3
									? project.process_creator_url
									: project.process_client_url) + urlEndPoint,
							responsibleUserId: project.responsible_user,
							loginUserId: loginUserId,
						};
						tableData.push(projectInfo);
					}
				});
			});
			return tableData;
		},
		/**
		 *
		 * @param {Array} clients Array of clients
		 * @returns Array after filter
		 */
		filterClientTable(clients, provinces) {
			let tableData = [];

			clients.forEach((client) => {
				let clientInfo = {
					id: client.id,
					clientname: client.client_name,
					prefectures: provinces[client.province_id],
					comment: client.remark,
					responsibleName: client.responsible_name,
				};
				tableData.push(clientInfo);
			});

			return tableData;
		},
		/**
		 *
		 * @param {Array} users Array of users
		 * @returns Array after filter
		 */
		filterUserTable(users, loginUserId) {
			let tableData = [];
			users.forEach((user) => {
				if (user.id != loginUserId && user.authority != 3) {
					let typeId = user.type - 1;
					let authorityId = user.authority - 1;
					let userInfo = {
						id: user.id,
						registername: user.register_name,
						companyname: user.company_name,
						type: TYPE[typeId],
						authority: AUTHORITY[authorityId],
					};
					tableData.push(userInfo);
				}
			});
			return tableData;
		},

		/**
		 *
		 * @param {Array} projects Array of projects
		 * @param {Array} clients Array of clients
		 * @param {Array} loginUserId
		 * @returns Array after filter
		 */
		filterProcessingProjectTable(projects, clients, schedules, loginUserId) {
			let tableData = [];

			projects.map((project) => {
				let clientId = project.client_id;
				if (project.status !== 5) {
					clients.map((client) => {
						if (client.id === clientId) {
							let urlEndPoint =
								this.userInfo?.authority != 3
									? this.getCreatorUrlEndPoint(project)
									: this.getClientUrlEndPoint(project);
							let schedule =
								schedules.filter((schedule) => schedule.project_id === project.id)[0] ||
								{};
							let projectInfo = {
								id: project.id,
								projectname: project.project_name,
								clientname: client.client_name,
								status: Boolean(project.creator_start)
									? statusList[project.status].text
									: 'スタート',
								startdate: project.project_start_date
									? moment(project.project_start_date).format('YYYY/MM/DD')
									: '',
								delivery: schedule.delivery_date
									? moment(schedule.delivery_date).format('YYYY/MM/DD')
									: '',
								url:
									(this.userInfo?.authority != 3
										? project.process_creator_url
										: project.process_client_url) + urlEndPoint,
								responsibleUserId: project.responsible_user,
								loginUserId: loginUserId,
								comment: project.comment,
							};
							tableData.push(projectInfo);
						}
					});
				}
			});

			return tableData;
		},

		createClientSelectOption(clientList) {
			let clientOptions = [];
			//Empty option to select
			const emptyOption = { value: '', label: '--選択なし--' };
			clientOptions.push(emptyOption);

			for (let index = 0; index < clientList.length; index++) {
				let item = {
					value: clientList[index]['id'],
					label: clientList[index]['client_name'],
					responsible_id: clientList[index]['responsible_id'],
				};
				clientOptions.push(item);
			}
			this.clientOptions = [...clientOptions];
		},

		async getEditProjectById(projectId) {
			let [response, error] = await await ProjectService.get(projectId);
			if (error) {
				console.log(error);
			}
			return response;
		},

		async getProvince() {
			try {
				let response = await ProvinceService.get();
				return response.data.response.prefecture;
			} catch (error) {
				throw error;
			}
		},

		async getCreative(projectId) {
			try {
				let [response, error] = await CreativeService.get(projectId);
				return response;
			} catch (error) {
				throw error;
			}
		},

		sortArrayByKey(array, key) {
			return array.sort((a, b) => {
				return b[key] - a[key];
			});
		},

		/**
		 * Get creator urlEndPoint base on project status
		 * @param {object} project Project Object
		 * @returns urlEndPoint
		 */
		getCreatorUrlEndPoint(project) {
			return Boolean(project.creator_start) ? statusList[project.status].value : '';
		},

		/**
		 * Get client urlEndPoint base on project status
		 * @param {object} project Project Object
		 * @returns urlEndPoint
		 */
		getClientUrlEndPoint(project) {
			return Boolean(project.client_start) ? statusList[project.status].value : '';
		},

		/**
		 * Check cretive is counting down, if true return proposal endpoint
		 * @param {Object} project Project objects
		 * @returns ClientApp url endPoint when creative is couting down
		 */
		async checkCreativeCountingDown(project) {
			let endPoint = this.getClientUrlEndPoint(project);
			// if (project.status !== PROPOSAL_SCREEN_ID) return endPoint;

			// let creative = await this.getCreative(project.id);
			// if (creative.status !== 'onCountingDown') return endPoint;

			// return statusList[CREATIVE_SCREEN_ID].value;
			return endPoint;
		},

		/**
		 * @param {array} array Raw array need to fillter
		 * @return {array} array with delete_flag = 0
		 */
		filterByDeleteFlag(array) {
			return array.filter((element) => element.delete_flag === 0);
		},

		fetchData() {
			const loginUserId = this.$route.params.userId || this.userInfo?.id;
			this.loginUserId = loginUserId;
			const userAuth = this.$route.params.authId || this.userInfo?.authority;
			const projectId = this.$route.query.projectid;
			const clientId = this.$route.query.clientid;

			this.clientId = clientId;
			this.projectId = projectId;
			this.userAuth = userAuth;

			this.filterTableData(loginUserId, userAuth);
			if (projectId) {
				this.filterdEditProjectData(projectId);
			}
			if (clientId) {
				this.filterEditClientProjectData(clientId);
			}
		},

		dataTransferListener: function (data) {
			console.log('new_data_transfer', data);
			if (data?.event_name === 'update_project_status') {
				this.fetchData();
			}
		},
	},

	mounted() {
		this.fetchData();

		socketClient.send('join', {
			userId: this.loginUserId,
			room: 'user' + this.loginUserId,
		});
	},
};
