import ApiService from './api.service';

const serviceURL = 'nft';
const NFTService = {
	/**
	 * Acquire NFT
	 * @returns {Object} NFT details
	 */
	getAll() {
		return ApiService.get(`${serviceURL}`);
	},

	/**
	 * Acquire NFT by nftId
	 * @param {Integer} nftId
	 * @returns {Object} NFT details
	 */
	getById(nftId) {
		return ApiService.get(`${serviceURL}`, `${nftId}`);
	},

	/**
	 * Acquire NFT by userId
	 * @param {Integer} userId
	 * @returns {Object} NFT details
	 */
	getByUser(userId) {
		return ApiService.get(`${serviceURL}/user/all/${userId}`);
	},

	/**
	 * Acquire NFT by projectId
	 * @param {Integer} projectId
	 * @returns {Object} NFT details
	 */
	getByProject(projectId) {
		return ApiService.get(`${serviceURL}/project`, `${projectId}`);
	},

	/**
	 * Acquire NFT by userId and projectId
	 * @param {Integer} userId
	 * @param {Integer} projectId
	 * @returns {Object} NFT details
	 */
	getByUserAndProject(userId, projectId) {
		return ApiService.get(
			`${serviceURL}/user/project`,
			`?user_id=${userId}&project_id=${projectId}`
		);
	},

	/**
	 * Create new nft record
	 * @param {Object} body {doc_content: {nft Object}}
	 * @returns {Object} include success message and record ID
	 */
	post(body) {
		return ApiService.post(`${serviceURL}/`, body);
	},

	/**
	 * Update nft content
	 * @param {Integer} nftId: NFT ID
	 * @param {Object} contents:
	 * @returns
	 */
	update(nftId, contents) {
		let body = {
			doc_content: contents,
		};
		console.log(body);
		return ApiService.put(`${serviceURL}/${nftId}`, body);
	},

	/**
	 * Delete nft record
	 * @param {Object} body {doc_content: [nft content]}
	 * @returns {Object} Success message
	 */
	delete(nftId, body) {
		return ApiService.deleteBulk(`${serviceURL}/${nftId}`, body);
	},
};

export default NFTService;
