export default [
	// add public route 27/7/2023 HoaDQ
	{ text: 'スケジュール', value: 'schedule' },
	{ text: 'キックオフミーティング', value: 'hearing' },
	// { text: '見積書作成', value: 'estimate' },
	{ text: 'コンセプトデザイン', value: 'decision' },
	// { text: 'クリエイティブ', value: 'creative' },
	// { text: 'ご提案', value: 'proposal' },
	// { text: '再提案設定', value: 'addcost' },
	// { text: '再提案', value: 'reproposal' },
	{ text: 'コンセプト仕上げ', value: 'delivery' },
	{ text: 'デジタル認証', value: 'nft' },
	{ text: '完了', value: 'finish' },
	{ text: '保留', value: '' },
];
