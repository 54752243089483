import ApiService from './api.service';

const serviceURL = 'schedule';

const ScheduleService = {
	getAll() {
		return ApiService.get(`${serviceURL}/`);
	},

	getScheduleByProjectId(projectId) {
		return ApiService.get(`${serviceURL}/${projectId}`);
	}
};

export default ScheduleService;